export const categoryIds = {
  all: "",
  art: "1",
  music: "2",
  programming: "3",
  farsi: "4",
  language: "5",
  sport: "6",
  science: "7",
  chess: "8",
};

export const categorySlugs = {
  "1": "art",
  "2": "music",
  "3": "programming",
  "4": "farsi",
  "5": "language",
  "6": "sport",
  "7": "science",
  "8": "chess",
};

export const categories = [
  {
    id: 2,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Music",
    nameFa: "موسیقی",
    parentId: null,
    youngParentId: null,
    level: 1,
    icon: "bf-i-ph-music-note",
    activeIcon: "bf-i-ph-music-note-fill",
  },
  {
    id: 5,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Language",
    nameFa: "زبان",
    parentId: null,
    youngParentId: null,
    level: 1,
    icon: "bf-i-ph-translate",
    activeIcon: "bf-i-ph-translate-fill",
  },

  {
    id: 4,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Farsi",
    nameFa: "فارسی",
    parentId: null,
    youngParentId: null,
    level: 1,
    icon: "bf-i-ph-book-open-text",
    activeIcon: "bf-i-ph-book-open-text-fill",
  },
  {
    id: 8,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Chess",
    nameFa: "شطرنج",
    parentId: null,
    youngParentId: null,
    level: 1,
    icon: "bf-i-ph-horse",
    activeIcon: "bf-i-ph-horse-fill",
  },
  {
    id: 7,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Science",
    nameFa: "ریاضی و علوم",
    parentId: null,
    youngParentId: null,
    level: 1,
    icon: "bf-i-ph-atom",
    activeIcon: "bf-i-ph-atom-fill",
  },
  {
    id: 6,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Sport",
    nameFa: "ورزش",
    parentId: null,
    youngParentId: null,
    level: 1,
    icon: "bf-i-ph-football",
    activeIcon: "bf-i-ph-football-fill",
  },

  {
    id: 3,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Programming",
    nameFa: "برنامه‌نویسی و نرم‌افزارهای کاربردی",
    parentId: null,
    youngParentId: null,
    level: 1,
    icon: "bf-i-ph-code-bold",
    activeIcon: "bf-i-ph-code-fill",
  },

  {
    id: 1,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Art",
    nameFa: "هنر",
    parentId: null,
    youngParentId: null,
    level: 1,
    icon: "bf-i-ph-palette",
    activeIcon: "bf-i-ph-palette-fill",
  },
];

export const subcategories = [
  {
    id: 9,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "English",
    nameFa: "انگلیسی",
    parentId: 5,
    youngParentId: null,
    level: 2,
  },
  {
    id: 10,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Physics and Astronomy",
    nameFa: "فیزیک و نجوم",
    parentId: 7,
    youngParentId: null,
    level: 2,
  },
  {
    id: 11,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "French",
    nameFa: "فرانسه",
    parentId: 5,
    youngParentId: null,
    level: 2,
  },
  {
    id: 12,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Farsi First Two Grades",
    nameFa: "اول و دوم دبستان",
    parentId: 4,
    youngParentId: null,
    level: 2,
  },
  {
    id: 13,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Wind Instruments",
    nameFa: "سازهای بادی",
    parentId: 2,
    youngParentId: null,
    level: 2,
  },
  {
    id: 14,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Math",
    nameFa: "ریاضی",
    parentId: 7,
    youngParentId: null,
    level: 2,
  },
  {
    id: 15,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Singing",
    nameFa: "آواز",
    parentId: 2,
    youngParentId: null,
    level: 2,
  },
  {
    id: 16,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "German",
    nameFa: "آلمانی",
    parentId: 5,
    youngParentId: null,
    level: 2,
  },
  {
    id: 17,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Piano",
    nameFa: "پیانو",
    parentId: 2,
    youngParentId: null,
    level: 2,
  },
  {
    id: 18,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Biology",
    nameFa: "زیست‌شناسی",
    parentId: 7,
    youngParentId: null,
    level: 2,
  },
  {
    id: 19,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Chinese",
    nameFa: "چینی",
    parentId: 5,
    youngParentId: null,
    level: 2,
  },
  {
    id: 20,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Percussion Instruments",
    nameFa: "سازهای کوبه‌ای",
    parentId: 2,
    youngParentId: null,
    level: 2,
  },
  {
    id: 21,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Zumba and Aerobics",
    nameFa: "زومبا و ایروبیک",
    parentId: 6,
    youngParentId: null,
    level: 2,
  },
  {
    id: 22,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Western String Instruments",
    nameFa: "سازهای زهی غربی",
    parentId: 2,
    youngParentId: null,
    level: 2,
  },
  {
    id: 24,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Pilates",
    nameFa: "پیلاتس",
    parentId: 6,
    youngParentId: null,
    level: 2,
  },
  {
    id: 26,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Arts and Crafts",
    nameFa: "هنرهای دستی",
    parentId: 1,
    youngParentId: null,
    level: 2,
  },
  {
    id: 27,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Cooking and Confectionery",
    nameFa: "آشپزی و شیرینی‌پزی",
    parentId: 1,
    youngParentId: null,
    level: 2,
  },
  {
    id: 28,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Traditional String Instruments",
    nameFa: "سازهای زهی سنتی",
    parentId: 2,
    youngParentId: null,
    level: 2,
  },
  {
    id: 55,
    created_at: "2024-11-02 07:09:15.288192+00",
    name: "Principles of Fashion and Clothing Design",
    nameFa: "اصول مد و طراحی لباس",
    parentId: 1,
    youngParentId: null,
    level: 2,
  },
  {
    id: 29,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Swedish",
    nameFa: "سوئدی",
    parentId: 5,
    youngParentId: null,
    level: 2,
  },
  {
    id: 30,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Yoga",
    nameFa: "یوگا",
    parentId: 6,
    youngParentId: null,
    level: 2,
  },
  {
    id: 31,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Turkish",
    nameFa: "ترکی استانبولی",
    parentId: 5,
    youngParentId: null,
    level: 2,
  },
  {
    id: 32,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Calligraphy",
    nameFa: "خوشنویسی و نقاشی خط",
    parentId: 1,
    youngParentId: null,
    level: 2,
  },
  {
    id: 33,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Painting",
    nameFa: "نقاشی و تصویرسازی",
    parentId: 1,
    youngParentId: null,
    level: 2,
  },
  {
    id: 35,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Performance Arts",
    nameFa: "هنرهای نمایشی",
    parentId: 1,
    youngParentId: null,
    level: 2,
  },
  {
    id: 39,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Applications",
    nameFa: "برنامه‌های کاربردی",
    parentId: 7,
    youngParentId: null,
    level: 2,
  },
  {
    id: 40,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Chemistry",
    nameFa: "شیمی",
    parentId: 7,
    youngParentId: null,
    level: 2,
  },
  {
    id: 41,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Sciences",
    nameFa: "علوم",
    parentId: 7,
    youngParentId: null,
    level: 2,
  },
  {
    id: 42,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Ballet",
    nameFa: "باله",
    parentId: 6,
    youngParentId: null,
    level: 2,
  },
  {
    id: 43,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Corrective Exercises",
    nameFa: "حرکات اصلاحی",
    parentId: 6,
    youngParentId: null,
    level: 2,
  },
  {
    id: 44,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Spanish",
    nameFa: "اسپانیایی",
    parentId: 5,
    youngParentId: null,
    level: 2,
  },
  {
    id: 45,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Russian",
    nameFa: "روسی",
    parentId: 5,
    youngParentId: null,
    level: 2,
  },
  {
    id: 50,
    created_at: "2024-09-11 11:57:56.415187+00",
    name: "Arabic",
    nameFa: "عربی",
    parentId: 5,
    youngParentId: null,
    level: 2,
  },
  {
    id: 51,
    created_at: "2024-09-11 11:58:23.257326+00",
    name: "Italian",
    nameFa: "ایتالیایی",
    parentId: 5,
    youngParentId: null,
    level: 2,
  },
  {
    id: 47,
    created_at: "2024-09-11 11:53:06.453611+00",
    name: "Mindfullness",
    nameFa: "ذهن‌آگاهی و مدیتیشن",
    parentId: 1,
    youngParentId: null,
    level: 2,
  },
  {
    id: 54,
    created_at: "2024-10-27 14:53:15.622841+00",
    name: "Kids Music",
    nameFa: "موسیقی کودک",
    parentId: 2,
    youngParentId: null,
    level: 2,
  },
  {
    id: 36,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Higher Levels",
    nameFa: "سال‌های بالاتر",
    parentId: 4,
    youngParentId: null,
    level: 2,
  },
  {
    id: 37,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Non-Persian Speaking Adults",
    nameFa: "بزرگسالان غیرفارسی‌زبان",
    parentId: 4,
    youngParentId: null,
    level: 2,
  },
  {
    id: 49,
    created_at: "2024-09-11 11:56:36.690819+00",
    name: "Reading of Classical Persian Texts",
    nameFa: "خوانش متون کهن فارسی",
    parentId: 4,
    youngParentId: null,
    level: 2,
  },
  {
    id: 52,
    created_at: "2024-09-11 12:22:46.018675+00",
    name: "Creative Writing",
    nameFa: "نویسندگی خلاق",
    parentId: 4,
    youngParentId: null,
    level: 2,
  },
  {
    id: 23,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Photography and Photoshop",
    nameFa: "عکاسی و فتوشاپ",
    parentId: 1,
    youngParentId: null,
    level: 2,
  },
  {
    id: 25,
    created_at: "2023-12-09 17:26:05.277829+00",
    name: "Fitness and Power Excercises",
    nameFa: "فیتنس و تمرینات قدرتی",
    parentId: 6,
    youngParentId: null,
    level: 2,
  },
  {
    id: 53,
    created_at: "2024-10-15 05:33:51.397628+00",
    name: "Growth of Critical Thinking",
    nameFa: "رشد تفکر خلاق",
    parentId: 1,
    youngParentId: null,
    level: 2,
  },
];
