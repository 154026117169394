import { useEffect, useState } from "react";
import Icon from "../ui/Icon";
import { useQuery } from "@tanstack/react-query";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import LoadingSpinner from "../ui/LoadingSpinner";
import HeroSearchResultsGrid from "./HeroSearchResultsGrid";
import { getFullName, getFullNameFa } from "@/utils/formatter";
import Space from "../ui/Space";
import { useRouter } from "next/router";
import InputDebaounced from "../InputDebounced";
import { calculateTeacherRating } from "@/utils/calculateRating";

export default function HeroSearch() {
  const [searchTerm, setSearchTerm] = useState();
  const [isResultsOpen, _setIsResultsOpen] = useState<boolean>(false);

  const setIsResultsOpen = (v) => {
    if (v) {
      const d = document.getElementById("search-box-top");
      d?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    _setIsResultsOpen(v);
  };

  useEffect(() => {
    setIsResultsOpen(!!searchTerm);
  }, [searchTerm]);

  const { locale } = useRouter();

  return (
    <>
      <div className="relative h-12 ">
        <Overlay isResultsOpen={isResultsOpen} setIsResultsOpen={setIsResultsOpen} />
        <div id="search-box-top" className="h-2"></div>
        <div className="z-100 absolute w-full  ">
          <SearchBox
            value={searchTerm}
            setValue={setSearchTerm}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                setIsResultsOpen(false);
              }
            }}
          />
        </div>
        <Space size="h-4" />
        {isResultsOpen && (
          <div
            className={`absolute top-16 ${
              locale === "en" ? "left-0" : "right-0"
            }  sm:max-w-70rem bg-white/80 b-1 b-white glass-effect rd-6 px-2  shd-tinted-3  z-100
           ${locale === "en" ? "lt-sm:left-50% lt-sm:-translate-x-50%" : "lt-sm:right-50% lt-sm:translate-x-50%"}
        `}
            style={{
              width: "calc(100vw - 2rem)",
            }}
          >
            <div className="px-4 grid items-center py-4 h-full overflow-y-auto min-h-30 max-h-100 ">
              <SaerchResults searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function Overlay({ isResultsOpen, setIsResultsOpen }) {
  const { locale } = useRouter();
  return (
    <div
      onClick={() => {
        if (isResultsOpen) setIsResultsOpen(false);
      }}
      className={`fixed w-200vw h-200vh z-10 inset-0 bg-sandA-3 backdrop-blur-5 ${
        locale === "en" ? "-translate-x-50%" : "translate-x-50%"
      }  -translate-y-50% transform-250ms select-none
        ${isResultsOpen ? "opacity-100" : "opacity-0 pointer-events-none"}
        `}
      style={{
        transform: "opacity 500ms ease",
      }}
    ></div>
  );
}

function SearchBox({ value, setValue, onKeyDown }) {
  const { locale } = useRouter();
  return (
    <div className=" px-4 py-2 rd-full shd-tinted-3  b-1 b-sand5 bg-sand1  w-full fw-400 min-w-40 max-w-140  flex-1 text-base flex items-center gap-2 focus-within:outline-brand-orange focus-within:outline-1px focus-within:outline-solid">
      <Icon name="bf-i-ph-magnifying-glass" className=" pt-1" />
      <label className="flex gap-2 items-center grow">
        <span className="sr-only">Search</span>
        <div className="grid grow">
          <InputDebaounced
            value={value ?? ""}
            onChange={setValue}
            className="focus:outline-none bg-transparent "
            placeholder={locale === "en" ? "Search subjects or tutors here" : "اینجا کلاس‌ها و معلم‌ها را جستجو کنید"}
            onKeyDown={onKeyDown}
          />
        </div>
      </label>
    </div>
  );
}

type ProfileQType = any;

function SaerchResults({ searchTerm, setSearchTerm }) {
  const supabase = useSupabaseClient();

  const profileQ = useQuery<ProfileQType[]>({
    queryKey: ["siteProfileTutors-hero-banner"],
    queryFn: async () => {
      let query = supabase
        .from("siteProfile")
        .select(
          `*,
          category (name , nameFa),
          topic (name, nameFa ),
          teacher(
            course(feedback(studentRating) ),
            englishFluency,
            expertise(sessionPriceInCAD, sessionDurationOnWebsiteInMinute, endDate),
            user(firstname, lastname, firstnameFa, lastnameFa)
          ),
          profileSubcategory!inner(categoryId)`
        )
        .eq("isTeacherIn", true);

      const { data, error }: any = await query;
      if (error) throw error;
      return data;
    },
  });

  return (
    <>
      {profileQ.isLoading && (
        <div className="flex justify-center items-center h-full ">
          <LoadingSpinner />
        </div>
      )}
      {profileQ.data && profileQ.data.length > 0 && (
        <HeroSearchResultsGrid
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          data={profileQ.data.map((profile) => {
            return {
              profileId: profile.id,
              teacherFullName: getFullName(profile.teacher.user),
              teacherFullNameFa: getFullNameFa(profile.teacher.user),
              topicName: profile.topic.name,
              topicNameFa: profile.topic.nameFa,
              englishFluency: profile.teacher.englishFluency,
              englishFluencyFa: profile.teacher.englishFluency,
              price: profile.teacher.expertise.find((exp) => exp.endDate === null)?.sessionPriceInCAD,
              rating: calculateTeacherRating(profile).rating,
              categoryName: profile.category.name,
              categoryNameFa: profile.category.nameFa,
              status: profile.status,
            };
          })}
        />
      )}
    </>
  );
}
