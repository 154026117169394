import { useRouter } from "next/router";
import { En, Fa } from "@/components/ui/multilang";
import BluredCircle from "./BluredCircle";
import DesktopOnly from "../ui/DesktopOnly";
import MobileOnly from "../ui/MobileOnly";
import Space from "../ui/Space";
import Icon from "@/components/ui/Icon";
import Image from "next/image";
import HeroSearch from "./HeroSearch";
import Link from "next/link";
import { sendGTMEvent } from "@next/third-parties/google";

export default function Hero() {
  return (
    <div>
      <MobileOnly>
        <MobileHero />
      </MobileOnly>
      <DesktopOnly>
        <DesktopHero />
      </DesktopOnly>
    </div>
  );
}

function DesktopHero() {
  return (
    <section className="relative  max-w-70rem mx-auto ">
      <BluredCircle radius={150} top="60%" left="90%" bg="bg-brand-light-amber" blur="200px" opacity={0.3} />

      <div className="flex flex-row gap-8 items-center justify-between  w-full ">
        <div className="flex flex-col justify-center min-w-80">
          <h1 className="H2 text-prm10 fw-800 line-height-0.75 ">
            <En>
              Private Online Courses
              <br />
              With Professional Tutors
            </En>
            <Fa>
              معلم خصوصی آنلاین
              <br />
              برای فارسی زبانان سراسر دنیا
            </Fa>
          </h1>
          <Space size="h-4" />
          <Fa></Fa>
          <En>
            <p className="c-melow leading-5">Achieve your goals faster with personalized learning</p>
          </En>
          <div className="h-4"></div>
          <HeroSearch />
          <div className="h-6"></div>
          <Link
            href="/subjects"
            onClick={() => sendGTMEvent({ event: "hero-cta-home-button-click" })}
            className="c-orange11 fw-500 subject-listing-cta-button"
          >
            <En>
              <span className="subject-listing-cta-button">
                or check all the subjects
                <Icon name="bf-i-ph-arrow-right subject-listing-cta-button" subdued={false} />
              </span>
            </En>
            <Fa>
              <span className="subject-listing-cta-button">
                یا همه کلاس‌ها را اینجا ببینید
                <Icon name="bf-i-ph-arrow-left subject-listing-cta-button" subdued={false} />
              </span>
            </Fa>
          </Link>
        </div>
        <DesktopHeroImage />
      </div>
    </section>
  );
}

function MobileHero() {
  return (
    <section className="relative ">
      <BluredCircle radius={100} top="20%" left="95%" bg="bg-brand-light-amber" blur="200px" opacity={0.4} />
      <BluredCircle radius={200} top="60%" left="5%" bg="bg-brand-light-pink" blur="200px" opacity={0.3} />

      <div className="flex flex-col  gap-8 items-center w-full max-w-70rem mx-auto  ">
        <div className="flex flex-col justify-center ">
          <h1 className="H2 text-prm10 fw-800 text-center line-height-0.75 ">
            <En>
              Learn with
              <br />
              Top-Notch Tutors Online
            </En>
            <Fa>کلاس خصوصی آنلاین با معلم‌های حرفه‌ای</Fa>
          </h1>
          <Space size="h-2" />
          <Fa></Fa>
          <En>
            <p className="c-sand11 leading-5 text-center">
              Empower Your Family&apos;s Learning.
              <br />
              Explore Subjects with Professional Online Tutors.
            </p>
          </En>
          <div className="h-4"></div>
          <MobileHeroImage />
          <div className="-mt-20">
            <HeroSearch />
            <div className="h-6"></div>
            <Link
              href="/subjects"
              onClick={() => sendGTMEvent({ event: "hero-cta-home-button-click" })}
              className="c-orange11 fw-500 subject-listing-cta-button"
            >
              <En>
                <span className="subject-listing-cta-button">
                  or check all the subjects
                  <Icon name="bf-i-ph-arrow-right subject-listing-cta-button" subdued={false} />
                </span>
              </En>
              <Fa>
                <span className="subject-listing-cta-button">
                  یا همه کلاس‌ها را اینجا ببینید
                  <Icon name="bf-i-ph-arrow-left subject-listing-cta-button" subdued={false} />
                </span>
              </Fa>
            </Link>
          </div>
        </div>
      </div>
      <Space size="h-20" />
      <ul className="flex flex-wrap gap-6 justify-around px-6 ">
        <FeatureCard color="bg-pink4" icon="bf-i-ph-devices" className="left-10% top-10%">
          <En>
            Access <br /> from Anywhere
          </En>
          <Fa>
            در دسترس <br /> از هرکجای دنیا
          </Fa>
        </FeatureCard>

        <FeatureCard color="bg-sky4" icon="bf-i-ph-piggy-bank" className="bottom-20% right-0% md:right-0% sm:right-5%">
          <En>
            Starting from $10 <br /> per Session
          </En>
          <Fa>
            از ۱۰ دلار کانادا
            <br />
            برای هر جلسه
          </Fa>
        </FeatureCard>

        <FeatureCard color="bg-yellow4" icon="bf-i-ph-star" className="-left-0% bottom-15%">
          <En>
            For the <br /> Whole Family
          </En>
          <Fa>
            معلم‌های حرفه‌ای
            <br />و متخصص
          </Fa>
        </FeatureCard>
      </ul>
    </section>
  );
}

function MobileHeroImage() {
  const { locale } = useRouter();

  return (
    <div className="relative  flex justify-center items-center   ">
      <div className="  rd-full h-55 w-55   absolute">
        <div className="bg-orangeA-6 w-full h-full rd-full"></div>
      </div>
      <Image
        src="/static/hero-image.webp"
        width={427}
        height={427}
        className={`w-60 w-60  scale-110 ${locale === "fa" && "scale-x--110"} `}
        alt=""
        priority
      ></Image>
    </div>
  );
}

function DesktopHeroImage() {
  const { locale } = useRouter();

  return (
    <div className={`sh   flex justify-end items-center grow   `}>
      <div className="relative grow   flex justify-center items-center max-w-30rem  ">
        <Circle />
        <Image
          src="/static/hero-image.webp"
          width={427}
          height={427}
          className={`sm:w-60 md:w-80  scale-110 ${locale === "fa" && "scale-x--110"} `}
          alt=""
          priority
        ></Image>
        <ul className="grid grid-cols-1 sm:grid-cols-3  sm:gap-4 gap-6">
          <FeatureCard color="bg-pink4" icon="bf-i-ph-devices" className="absolute left-10% top-10%">
            <En>
              Access <br /> from Anywhere
            </En>
            <Fa>
              در دسترس <br /> از هرکجای دنیا
            </Fa>
          </FeatureCard>
          <FeatureCard
            color="bg-sky4"
            icon="bf-i-ph-piggy-bank"
            className="absolute bottom-20% right-0% md:right-0% sm:right-5%"
          >
            <En>
              Starting from $10 <br /> per Session
            </En>
            <Fa>
              از ۱۰ دلار کانادا
              <br />
              برای هر جلسه
            </Fa>
          </FeatureCard>
          <FeatureCard color="bg-yellow4" icon="bf-i-ph-star" className="absolute -left-5% bottom-15%">
            <En>
              For the <br /> Whole Family
            </En>
            <Fa>
              معلم‌های حرفه‌ای
              <br />و متخصص
            </Fa>
          </FeatureCard>
        </ul>
      </div>
    </div>
  );
}
function FeatureCard({ color, children, icon, className = "" }) {
  return (
    <li
      className={`  shd-tinted-3  py-1 px-2 md:p-2 rd-3 text-xs line-height-0.8  flex   md:flex-row gap-2 items-center  ${className} bg-white/80 glass-effect`}
    >
      <FeatureIcon color={color} icon={icon} />
      <div className="flex-1 c-melow flex items-center">
        <p className="fs-xs  md:fs-xs line-height-0.8">{children}</p>
      </div>
    </li>
  );
}

function FeatureIcon({ color, icon }) {
  return (
    <div className={`w-5 md:w-10 h-5 md:h-10 rd-full ${color} flex justify-center items-center shd-tinted-3`}>
      {/* <Icon name="bf-i-ph-list" /> */}
      <Icon name={icon} className="text-sm md:text-lg c-brand-dark-blue" subdued={false} />
    </div>
  );
}

function Circle() {
  return (
    <div className="  h-full w-full absolute flex items-center justify-center">
      <div className="bg-orangeA-6 aspect-ratio-1/1 h-full rd-full"></div>
    </div>
  );
}
