import { useRouter } from "next/router";
import { En, Fa } from "@/components/ui/multilang";
import BluredCircle from "./BluredCircle";
import Space from "../ui/Space";
import Icon from "../ui/Icon";
import DesktopOnly from "../ui/DesktopOnly";
import MobileOnly from "../ui/MobileOnly";

export default function Stats() {
  const { locale } = useRouter();
  return (
    <section className="relative max-w-70rem mx-auto">
      <BluredCircle radius={150} top="80%" left="0%" bg="bg-brand-light-amber" blur="200px" opacity={0.2} />
      <div className=" ">
        <div className="">
          <h2 className="sr-only H2 c-title">{locale === "fa" ? "آماروارقام" : "Statistics"}</h2>
          <div className="h-4"></div>
          <ul
            className=" mx-auto justify-center "
            style={{ gridTemplateColumns: "repeat(4 , auto)", gridTemplateRows: "auto auto", gridAutoFlow: "dense" }}
          >
            <div className="flex justify-center gap-8 items-center w-full"></div>
            <Space size="h-18" />

            <div
              className={`
             flex justify-between
                gap-6

                lt-sm:grid
                lt-sm:justify-center
                `}
              style={{
                gridTemplateAreas: "'a b' 'c d' 'e e'",
              }}
            >
              <SessionStat />
              <StudentStat />
              <TopicStat />
              <TeacherStat />
              <RatingStat />
            </div>
          </ul>
        </div>
      </div>
    </section>
  );
}

function SessionStat() {
  return (
    <Stat
      number={
        <span className="">
          <Fa>
            <span className="inline-flex items-baseline flex-row-reverse">
              <span>۱۰۰</span>
              <span className="relative top-4">٬</span>
              <span>۰۰۰</span>
            </span>
            +
          </Fa>
          <En>+100k</En>
        </span>
      }
      label={
        <>
          <En>Sessions</En>
          <Fa>جلسه</Fa>
        </>
      }
    />
  );
}

function StudentStat() {
  return (
    <Stat
      number={
        <>
          <Fa>۳۰۰۰+</Fa>
          <En>+3000</En>
        </>
      }
      label={
        <>
          <En>Students</En>
          <Fa>شاگرد</Fa>
        </>
      }
    />
  );
}

function TopicStat() {
  return (
    <Stat
      number={
        <>
          {" "}
          <Fa>۱۰۰+</Fa>
          <En>+100</En>
        </>
      }
      label={
        <>
          <En>Subjects</En>
          <Fa>رشته</Fa>
        </>
      }
    />
  );
}

function TeacherStat() {
  return (
    <Stat
      number={
        <>
          {" "}
          <Fa>۲۰۰+</Fa>
          <En>+200</En>
        </>
      }
      label={
        <>
          <En>Tutors</En>
          <Fa>معلم</Fa>
        </>
      }
    />
  );
}

function RatingStat() {
  const { locale } = useRouter();
  return (
    <Stat
      className="grid-col-span-2 lt-sm:text-center "
      number={
        <span className={`inline-flex items-center text-0.8em  ${locale === "fa" && "flex-row-reverse"} `}>
          <DesktopOnly>
            <En>4.9</En>
            <Fa>۴.۹ </Fa>
          </DesktopOnly>
          {` `}
          <Icon name="bf-i-ph-star-fill" className="c-brand-orange !before:scale-90 mis-1" subdued={false} />
          <Icon name="bf-i-ph-star-fill" className="c-brand-orange !before:scale-90" subdued={false} />
          <Icon name="bf-i-ph-star-fill" className="c-brand-orange !before:scale-90" subdued={false} />
          <Icon name="bf-i-ph-star-fill" className="c-brand-orange !before:scale-90" subdued={false} />
          <Icon name="bf-i-ph-star-fill" className="c-brand-orange !before:scale-90" subdued={false} />
        </span>
      }
      label={
        <span className={`lt-sm:text-3xl  ${locale === "en" && "tracking-tighter"} lt-sm:fw-700 !lt-sm:leading-0.9em`}>
          <MobileOnly>
            <En>4.9</En>
            <Fa>۴.۹ </Fa>
            {` `}
          </MobileOnly>
          <En>Average Rating</En>
          <Fa>میانگین امتیار‌ها</Fa>
        </span>
      }
    />
  );
}

function Stat({ number, label, className = "" }) {
  const { locale } = useRouter();

  return (
    <li
      className={` px-2 py-2
    ${className}`}
    >
      <p className="font-display grid ">
        <span
          className={`text-4xl sm:text-3xl md:text-4xl ${locale === "en" && "tracking-tighter"} fw-700 !leading-0.9em`}
        >
          {number}
        </span>
        <span className=" text-xl  sm:text-xl  md:text-2xl tracking-tight  tracking-tighter c-melow !leading-0.9em fw-400">
          {label}
        </span>
      </p>
    </li>
  );
}
