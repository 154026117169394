import LinkButton from "@/components/ui/button/LinkButton";
import { En, Fa } from "@/components/ui/multilang";
import BluredCircle from "./BluredCircle";
import Icon from "@/components/ui/Icon";
import { useRouter } from "next/router";
import { sendGTMEvent } from "@next/third-parties/google";

export default function CTA() {
  const router = useRouter();
  const { locale } = router;

  return (
    <section className="relative mx-auto max-w-70rem ">
      <BluredCircle radius={200} top="10%" left="5%" bg="bg-brand-light-amber" blur="200px" opacity={0.4} />
      <BluredCircle radius={200} top="60%" left="75%" bg="bg-brand-light-pink" blur="200px" opacity={0.4} />

      <div className=" ">
        <div className="bg-gradient-to-rb from-brand-light-amber/40 via-brand-light-cream/20 to-brand-light-pink  rd-8 relative isolate shd-tinted-5 b-1 b-white/20">
          <div
            className="absolute inset-0 rd-8 opacity-30 -z-1"
            style={{
              backgroundImage: "url('/static/noise.svg')",
              backgroundSize: "auto",
              backgroundRepeat: "repeat",
              // mix-blend-mode: screen;
              // mixBlendMode: "hue"
            }}
          ></div>

          <div className="h-24"></div>
          <p className="H2 lt-sm:text-2xl c-prm10 text-center line-height-0.75">
            <En>
              Your learning journey
              <br />
              starts here.
            </En>
            <Fa>
              <span className="fw-900 ">
                سفر یادگیری شما
                <br />
                از اینجا شروع می‌شود.
              </span>
            </Fa>
          </p>
          <div className="h-8"></div>

          <div className="flex justify-center items-center">
            <LinkButton
              variation="solid-prm"
              href="/subjects"
              onClick={() => sendGTMEvent({ event: "last-cta-home-button-click" })}
              className="text-lg sm:text-xl flex gap-2 items-center w-fit shd-tinted-3 subject-listing-cta-button"
            >
              {locale === "fa" ? "معلم خود را انتخاب کنید" : "Find the Best Tutor"}
              <Fa>
                <Icon name="bf-i-ph-arrow-left" subdued={false} />
              </Fa>
              <En>
                <Icon name="bf-i-ph-arrow-right" subdued={false} />
              </En>
            </LinkButton>

            {/* <div className="w-20 h-20 bg-red5 shd-tinted-2"></div> */}
          </div>
          <div className="h-24"></div>
        </div>
        <div className="h-24"></div>
      </div>
    </section>
  );
}
