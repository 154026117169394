import { DExpertise, DFeedback, DProfileSubcategory, DSiteProfile, DTeacher, DTopic, DUser } from "@/types";

type ProfileQType = DSiteProfile & { topic: Pick<DTopic, "name" | "nameFa"> } & {
  teacher: Pick<DTeacher, "englishFluency"> & { course: { feedback: Pick<DFeedback, "studentRating">[] }[] } & {
    expertise: Pick<DExpertise, "sessionPriceInCAD" | "sessionDurationOnWebsiteInMinute" | "endDate">[];
  } & { user: Pick<DUser, "firstname" | "lastname" | "firstnameFa" | "lastnameFa"> };
} & { profileSubcategory: { categoryId: Pick<DProfileSubcategory, "categoryId"> } };

export function calculateTeacherRating(profile: ProfileQType) {
  const hasRatings = profile.teacher.course.some((course) =>
    course.feedback.some((feedback) => feedback.studentRating !== null)
  );

  if (!hasRatings)
    return {
      rating: -1,
      numberOfFeedbacks: 0,
    };

  const numberOfFeedbacks = profile.teacher.course
    .filter((course) => course.feedback.some((feedback) => feedback.studentRating !== null))
    .reduce((totalFeedbacks, course) => totalFeedbacks + course.feedback.length, 0);

  const sumOfFeedbacks = profile.teacher.course
    .filter((course) => course.feedback.some((feedback) => feedback.studentRating !== null))
    .reduce((totalRatings, course) => {
      const courseRatings = course.feedback.reduce((acc, feedback) => acc + (feedback.studentRating || 0), 0);
      return totalRatings + courseRatings;
    }, 0);
  return {
    rating: sumOfFeedbacks / numberOfFeedbacks,
    numberOfFeedbacks,
  };
}
