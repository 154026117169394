import Link from "next/link";
import { useRouter } from "next/router";
import persianJs from "persianjs";
import { En, Fa } from "@/components/ui/multilang";
import BluredCircle from "./BluredCircle";
import Space from "../ui/Space";
import Icon from "@/components/ui/Icon";

export default function Process() {
  const { locale } = useRouter();

  return (
    <section className=" relative max-w-70rem mx-auto">
      {/* <BluredCircle radius={200} top="100%" left="95%" bg="bg-brand-light-blue" blur="200px" /> */}
      <BluredCircle radius={200} top="50%" left="95%" bg="bg-brand-light-pink" blur="200px" opacity={0.5} />

      <BluredCircle radius={150} top="0%" left="5%" bg="bg-brand-light-amber" blur="200px" opacity={0.3} />

      <div className="">
        <h2 className="H2 c-title  pb-4">{locale === "fa" ? "درسون چگونه کار می‌کند؟" : "How Darsoon works"}</h2>
        <p className="c-melow max-w-50ch">
          {locale === "fa"
            ? "در هر کجای دنیا که باشید، می توانید به راحتی با یک معلم خصوصی و با هزینه ای مناسب هر آنچه را که دوست دارید یاد بگیرید. تنها کاری که باید انجام دهید این است که:"
            : "No matter where you are in the world, you can easily learn anything you want with a private tutor at an affordable price. Here are the steps:"}
        </p>
        <div className="h-8 md:h-20  "></div>
        <ol className="relative isolate grid grid-cols-1 md:grid-cols-4  ">
          <DashedLine />
          <ProcessCard
            title={locale === "en" ? "Find your tutor" : "انتخاب کلاس"}
            stepNumber={1}
            description={
              locale === "fa"
                ? "در صفحه‌ی پروفایل معلم مورد نظرتان در وبسایت درسون بر روی دکمه‌ی «درخواست جلسه معرفی رایگان» کلیک کنید و فرم کوتاه مربوطه را کامل کنید."
                : "Select the subject and tutor of your choice and complete the free introductory meeting form."
            }
          />
          <ProcessCard
            title={locale === "en" ? "Attend an Intro Session" : "هماهنگی و برگزاری جلسه معرفی"}
            stepNumber={2}
            description={
              locale === "fa"
                ? "معلم از طریق واتساپ یا ایمیل زمان جلسه معرفی رایگان را با شما هماهنگ می‌کند. این جلسه تصویری است تا شما و معلم یکدیگر را بهتر بشناسید و مطمئن شوید در این کلاس انتظاراتتان برآورده می‌شود."
                : "The tutor will contact you to arrange a free introductory meeting."
            }
          />
          <ProcessCard
            title={locale === "en" ? "Book Sessions" : "رزرو جلسات کلاس"}
            stepNumber={3}
            description={
              locale === "fa"
                ? "در صورت تمایل به شروع کلاس، از صفحه پروفایل معلم پکیج یک، ۵، ۱۲ یا ۲۰ جلسه‌ای را انتخاب کرده و پرداختتان را کامل کنید"
                : "If you wish to continue, make the necessary payment to book a single session or packages of 5, 12 or 20 on the tutor's profile page"
            }
          />
          <ProcessCard
            title={locale === "en" ? "Keep Learning" : "شروع کلاس"}
            stepNumber={4}
            description={
              locale === "fa"
                ? "زمان شروع جلسات رو با خود معلم به‌طورمستقیم برنامه‌ریزی کنید."
                : "Coordinate subsequent sessions directly with your tutor."
            }
          />
        </ol>
        <Space size="h-12" />
        <div className="text-end">
          <En>
            <Link href="/process">
              Check our visual step-by-step guide
              <Icon name="bf-i-ph-arrow-right mis-2" />
            </Link>
          </En>

          <Fa>
            <Link href="/process">
              راهنمای تصویری فرآیند گرفتن کلاس
              <Icon name="bf-i-ph-arrow-left mis-2" />
            </Link>
          </Fa>
        </div>
      </div>
    </section>
  );
}

function ProcessCard({ stepNumber, title = "", description }) {
  const { locale } = useRouter();

  return (
    <li className=" flex  md:flex-col items-stretch  ">
      <ItemNumber number={locale === "fa" ? persianJs(stepNumber.toString()).englishNumber().toString() : stepNumber} />
      <div className="md:h-12"></div>
      <div className=" grid content-center  lt-md:pis-4 md:pt-6 md:pis-8  ">
        <h3 className="font-bold text-xl sm:text-2xl font-display leading-8 ">
          <span>{title}</span>
        </h3>
        <p className="leading-5 c-melow lt-md:text-sm">{description}</p>
      </div>
    </li>
  );
}

function ItemNumber({ number }) {
  return (
    <div
      className={`  flex flex-col md:flex-row justify-center  w-full content-center items-center
       lt-sm:max-w-20 lt-sm:min-w-20
       lt-md:max-w-25 lt-md:min-w-25
       lt-md:min-h-35
       `}
      style={{
        flexGrow: 0,
      }}
    >
      {/* <DashedLine /> */}
      <NumberCircle number={number} />
      {/* <DashedLine /> */}
    </div>
  );
}

function DashedLine({ className }: { className?: string }) {
  const { locale } = useRouter();
  return (
    <>
      <div className="md:contents display-none">
        <div className={`absolute -z-10 -top-12  w-full  ${locale === "fa" && "scale-x--100"}`}>
          <img src="/static/process-line.svg" alt="" className="w-full h-full " />
        </div>
      </div>
      <div className="lt-md:contents md:display-none">
        <div className={`absolute -z-10  top-0 bottom-0 ${locale === "en" ? "left-4" : "-right-12 scale-x--100"} `}>
          <img src="/static/process-line-vertical.svg" className="h-full " alt="" />
        </div>
      </div>
    </>
  );
}

function NumberCircle({ number }) {
  return (
    <div className="rd-full w-16 h-16 md:w-22 md:h-22 bg-brand-warm-black shd-3 flex justify-center items-center shd-tinted-3">
      <span className="text-3xl md:text-5xl font-display c-white ">{number}</span>
    </div>
  );
}
